import React, { useState } from "react";
import useWeb3 from "../hooks/useWeb3";
import useIsStarted from "../hooks/useIsStarted";
import useMint from "../hooks/useMint";
import ClaimView from "../components/claimView";
import "./index.css"

export default function Index () {
    /** WEB 3 */
    const [
        provider,
        signer,
        address,
        contractNFT,
        contractSales,
        isConnected,
        isCorrectChain,
        tryAutoConnect,
        triedAutoConnecting,
        setTriedAutoConnecting,
        connect,
        isConnecting,
      ] = useWeb3();

    /** TIMING */
    const [
        now,
        isStarted,
        textDay,
        textHour,
        textMin,
        textSec,       
    ] = useIsStarted();

    const [
        isDataLoaded,
        dogesIds,
        dogeClaimable,
        canUserClaim,
        claim,
        isMinting,
        error,
        txHash
    ] = useMint(contractSales, contractNFT, signer, provider, address)

    return (
       <ClaimView
            connect={connect}
            isConnected={isConnected}
            isStarted={isStarted}
            textDay={textDay}
            textHour={textHour}
            textMin={textMin}
            textSec={textSec}
            isDataLoaded={isDataLoaded}
            dogeClaimable={dogeClaimable}
            canUserClaim={canUserClaim}
            claim={claim}
            isMinting={isMinting}
            error={error}
            txHash={txHash}
            isConnecting={isConnecting}
       />
    )
}