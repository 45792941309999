import React from "react";
import "./index.css"

export default function ThankYou() {
    return (
        <div className="page-w">
    <div className="page-content">
      <div className="section-free-mint-t-hero wf-section">
        <div className="discord-hero-bg-w discord-hero-bg-w-mint-t">
          <div className="discord-hero-bg-c discord-hero-bg-c-mint-t">
            <div className="discord-hero-bg-b-1 discord-hero-bg-b-1-free-mint-t"><img src="images/discord-hero-bg-1.png" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 384px, (max-width: 991px) 448px, 480px" srcset="images/discord-hero-bg-1-p-500.png 500w, images/discord-hero-bg-1.png 526w" alt="" className="discord-hero-bg-e-1 discord-hero-bg-e-1-free-mint-t"/></div>
            <div className="discord-hero-bg-b-2 discord-hero-bg-b-2-free-mint-t"><img src="images/discord-hero-bg-2.png" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 384px, (max-width: 991px) 448px, 480px" srcset="images/discord-hero-bg-2-p-500.png 500w, images/discord-hero-bg-2.png 579w" alt="" className="discord-hero-bg-e-2 discord-hero-bg-e-2-mint-t-copy"/></div>
            <div className="discord-hero-bg-b-3 discord-hero-bg-b-3-mint-t"><img src="images/raffle-hero-bg-3.png" loading="lazy" sizes="(max-width: 1439px) 100vw, 1440px" srcset="images/raffle-hero-bg-3-p-500.png 500w, images/raffle-hero-bg-3-p-800.png 800w, images/raffle-hero-bg-3-p-1080.png 1080w, images/raffle-hero-bg-3.png 1440w" alt="" className="discord-hero-bg-e-3 discord-hero-bg-e-3-mint-t"/></div>
            <div className="raffle-hero-bg-grey raffle-hero-bg-grey-mint-t"></div>
          </div>
        </div>
        <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
          <div data-w-id="72d41d14-5454-c9cc-84f7-145c017696fc" className="navbar-bg-w"></div>
          <div className="page-padd">
            <div className="page-container-wide">
              <div className="padd-vert-navbar">
                <div className="navbar-cont-w">
                  <a href="https://www.boreddogeclub.io/" className="brand-img-c w-nav-brand">
                    <div className="brand-img-b"><img src="images/bdc-logo-2.svg" loading="lazy" alt="" className="brand-img-e"/></div>
                  </a>
                  <div className="navbar-cont-c-r">
                    <div className="navbar-button-w">
                      <div className="navbar-button-b">
                        <a href="https://discord.gg/boreddogeclub" target="_blank" className="button w-inline-block">
                          <div className="button-hover-b"></div>
                          <div className="button-img-b"><img src="images/Discord-Logo-White_1Discord-Logo-White.png" loading="lazy" alt="" className="button-img-e"/><img src="images/logo-discord-black_1logo-discord-black.png" loading="lazy" alt="" className="button-img-e-black"/></div>
                          <div className="button-text-b">
                            <div className="button-text-e">Join the Discord</div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="menu-button w-nav-button">
                      <div className="w-icon-nav-menu"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-padd">
          <div className="page-container">
            <div className="padd-vert-discord-hero padd-vert-discord-hero-free-mint-t">
              <div className="discord-hero-cont-w discord-hero-cont-w-mint-t">
                <div className="discord-hero-cont-c discord-hero-cont-c-mint-t">
                  <div className="raffle-t-hero-head-b raffle-t-hero-head-b-mint-t">
                    <h3 className="raffle-hero-head-e raffle-hero-head-e-mint-t">Congratulations!</h3>
                  </div>
                  <div className="raffle-t-hero-subhead-b raffle-t-hero-subhead-b-mint-t">
                    <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-t">You have just received your FREE Bored Doge NFT(s)</h3>
                  </div>
                  <div className="free-mint-t-secret-b">
                    <div className="mint-text-e"><span className="text-black">LITTLE-KNOWN SECRET <br/></span> <strong>HODL 2x Bored Doge NFTs to discover something unexpected... scary... <br/>and much, much rarer... by getting your hands on the</strong></div>
                  </div>
                  <div className="mint-t-hero-subhead-2-b">
                    <h3 className="raffle-hero-head-e raffle-hero-head-e-mint-t text-blue">SUPER RARE MUTANT DOGE!</h3>
                  </div>
                  <div className="mint-t-steps-w">
                    <div className="mint-t-steps-c">
                      <div id="w-node-_2d634251-5853-b85f-6417-a6d1d561227f-078a7a9e" className="mint-t-step-b">
                        <div className="step-num-c">
                          <div className="step-num-b"><img src="images/step-1.svg" loading="lazy" alt="" className="step-num-e"/></div>
                        </div>
                        <div className="step-text-b">
                          <div className="step-text-e">The future of the club is here! All HODLers of 2 Bored Doge NFTs will receive the serum responsible for creating the Mutant Doges</div>
                        </div>
                      </div>
                      <div id="w-node-_6f36df59-1134-be74-1851-0ffb8e6193ae-078a7a9e" className="mint-t-step-b">
                        <div className="step-num-c">
                          <div className="step-num-b"><img src="images/step-2.svg" loading="lazy" alt="" className="step-num-e"/></div>
                        </div>
                        <div className="step-text-b">
                          <div className="step-text-e">By combining the hairs of your 2 Bored Doge NFTs with the serum, you will create something truly remarkable and incredibly rare</div>
                        </div>
                      </div>
                      <div id="w-node-_7352e8ad-40de-34d7-4fd8-b5bdee4289e4-078a7a9e" className="mint-t-step-b">
                        <div className="step-num-c">
                          <div className="step-num-b"><img src="images/step-3.svg" loading="lazy" alt="" className="step-num-e"/></div>
                        </div>
                        <div className="step-text-b">
                          <div className="step-text-e">You will create an ULTRA RARE MUTANT DOGE and be ushered into a new future…</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="raffle-t-text-b raffle-t-text-b-2-free-mint-t">
                    <p className="raffle-t-text-e free-mint-text-1-e">If you have more than 1 Bored Doges you can create as many mutants per pair of Bored Doges you have.</p>
                  </div>
                  <div className="free-mint-future-b">
                    <h3 className="raffle-hero-subhead-timer free-mint-subhead-e-2">A future only heard in whispers throughout the Metaverse… A future everyone fears…</h3>
                  </div>
                  <div className="mint-t-hero-subhead-2-b">
                    <h3 className="raffle-hero-head-e raffle-hero-head-e-mint-t text-blue">Welcome to the MUTANT DOGE CLUB</h3>
                  </div>
                  <div className="free-mint-dark-side-b">
                    <h3 className="raffle-hero-subhead-timer free-mint-subhead-e-3">The dark side of the Metaverse - where a whole <br/>new adventure awaits.</h3>
                  </div>
                  <div className="mint-t-hero-subhead-2-b">
                    <h3 className="raffle-hero-head-e raffle-hero-head-e-mint-t text-blue">WHAT THIS MEANS?</h3>
                  </div>
                  <div className="free-mint-text-2-b">
                    <p className="mint-text-e mint-text-e-free">In simple terms: <br/><span className="text-21"><strong>It’s better to KEEP YOUR DOGES than sell them. <br/></strong></span><br/></p>
                    <div className="text-block-4">Those who HODL their Doges for 2 weeks after the reveal will gain access to the serum that creates a MUTANT DOGE. A much rarer NFT, which can only be obtained using this method     </div><img src="images/fxemoji_fire.svg" loading="lazy" alt="" className="free-mint-img-e"/>
                    <p className="mint-text-e mint-text-e-free-3">‍<br/>Be careful, if you sell your Bored Doge, you lose the possibility to win a free Mutant Doge.</p>
                  </div>
                  <div className="free-mint-rocket-c">
                    <div className="free-mint-rocket-text-b">
                      <h3 className="raffle-hero-subhead-timer free-mint-subhead-e-2">Are you ready for the future?</h3>
                    </div>
                    <div className="free-mint-img-b"><img src="images/emojione_rocket.svg" loading="lazy" alt="" className="free-mint-rocket-img-e"/></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="discord-section-footer wf-section">
        <div className="page-padd">
          <div className="page-container">
            <div className="padd-vert-footer-main padd-vert-footer-main-disc">
              <div className="footer-cont-w">
                <div className="footer-cont-c">
                  <div className="footer-head-b">
                    <a href="https://www.boreddogeclub.io/" className="brand-img-c w-nav-brand">
                      <div className="brand-img-b-footer"><img src="images/bdc-logo-2.svg" loading="lazy" alt="" className="brand-img-e"/></div>
                    </a>
                  </div>
                  <div className="footer-social-w">
                    <div className="footer-soc-c">
                      <div className="home-hero-soc-b">
                        <a href="https://twitter.com/boredogeclub" target="_blank" className="soc-c w-inline-block">
                          <div className="soc-b"><img src="images/logo-twitter-white.svg" loading="lazy" alt="" className="soc-img-e"/><img src="images/logo-twitter-blue.svg" loading="lazy" alt="" className="soc-img-e-an"/></div>
                        </a>
                      </div>
                      <div className="home-hero-soc-b">
                        <div className="button-discord-w">
                          <div className="discord-letters-w rotate-e"></div>
                          <a href="https://discord.gg/boreddogeclub" target="_blank" className="button-discord w-inline-block">
                            <div className="button-hover-b button-hover-b-disc"></div>
                            <div className="button-discord-circle">
                              <div className="button-discord-c">
                                <div className="button-discord-b"><img src="images/Discord-logo-white-33_1Discord-logo-white-33.png" loading="lazy" alt="" className="button-discord-e"/></div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="home-hero-soc-b">
                        <a href="https://www.instagram.com/boreddogeclub/" target="_blank" className="soc-c w-inline-block">
                          <div className="soc-b"><img src="images/logo-insta-white.svg" loading="lazy" alt="" className="soc-img-e"/><img src="images/logo-insta-blue.svg" loading="lazy" alt="" className="soc-img-e-an"/></div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="footer-copyright-b">
                    <div className="footer-copyright-e">© 2022 Bored Doge Club NFT. All rights reserved</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    )
}