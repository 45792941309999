import React, { useEffect } from "react"
import Config from "../config"

export default function ClaimView({
    connect,
    isConnected,
    isStarted,
    textDay,
    textHour,
    textMin,
    textSec,
    isDataLoaded,
    bullClaimable,
    canUserClaim,
    claim,
    isMinting,
    error,
    txHash,
    isConnecting
}) {
    useEffect(() => {
        if (isConnected && isDataLoaded()) {
            claim()
        }
    }, [isConnected, isDataLoaded()])

    const NotStarted = () => {
        return (
            <>
                {
                    !isStarted() &&
                        <>
                            <div className="mint-1-hero-head-b">
                                <h1 className="mint-1-hero-head-e">CONGRATULATIONS!</h1>
                            </div>
                            <div className="mint-1-hero-subhead-timer">
                                <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">EARLY HOLDERS OFFER</h3>
                            </div>
                            <div className="mint-1-button-w">
                                <a href="#" className="button-raffle w-button">Claim your Free NFT</a>
                            </div>
                            <div className="mint-1-hero-subhead-timer">
                                <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">FREE MINT OPENING IN</h3>
                                <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1-2">FREEMINT NOW OPEN FOR EARLY HOLDERS</h3>
                            </div>

                        </>
                }
            </>
        )
    }
    
    const UserClaim = () => {
        return (
            <>
                { isStarted() &&
                    <>
                        <div className="mint-1-hero-head-b">
                            <h1 className="mint-1-hero-head-e">CONGRATULATIONS!</h1>
                        </div>
                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">EARLY HOLDERS OFFER</h3>
                        </div>
                        {
                            !isMinting && !isConnecting && !isDataLoaded() && !isConnected &&
                            <div className="mint-1-button-w">
                                <span className="button-raffle w-button" onClick={connect}>Claim your Free NFT</span>
                            </div>
                        }
                        {
                            (isConnecting || (isConnected && !isDataLoaded())) && !error &&
                            <>
                                <div className="mint-1-hero-subhead-timer">
                                    <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">Loading your free claim...</h3>
                                </div>
                            </>
                        }
                        {
                            isMinting && !error &&
                            <>
                                <div className="mint-1-hero-subhead-timer">
                                    <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">Your free Doges are being minted to your wallet...</h3>
                                </div>
                                {
                                    txHash &&
                                    <div className="mint-1-button-w">
                                        <a href={Config.URLS.etherscan + txHash} target="_blank" className="button-raffle w-button">See Transaction</a>
                                    </div>
                                }
                            </>
                        }
                        {
                            error && 
                            <>
                                <div className="mint-1-hero-subhead-timer">
                                    <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1" style={{color: "red"}}>{error}</h3>
                                </div>
                            </>
                        }
                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">FREE MINT OPENING IN</h3>
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1-2">FREEMINT NOW OPEN FOR EARLY HOLDERS</h3>
                        </div>
                    </>
                }
            </>
        )
    }

    return (
        <>
            <div className="page-w">
                <div className="page-content">
                <div className="section-mint-1-hero wf-section">
                    <div className="discord-hero-bg-w discord-hero-bg-w-mint-1">
                    <div className="discord-hero-bg-c discord-hero-bg-c-mint-1">
                        <div className="discord-hero-bg-b-1 discord-hero-bg-b-1-mint-1"><img src="images/free-mint-bg-left.png" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 407.1875305175781px, (max-width: 991px) 58vw, 508.9930725097656px" srcset="images/free-mint-bg-left-p-500.png 500w, images/free-mint-bg-left.png 509w" alt="" className="discord-hero-bg-e-1 discord-hero-bg-e-1-mint-1"/></div>
                        <div className="discord-hero-bg-b-2 discord-hero-bg-b-2-mint-1"><img src="images/free-mint-bg-2.png" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 437.5868225097656px, (max-width: 991px) 61vw, 546.99658203125px" srcset="images/free-mint-bg-2-p-500.png 500w, images/free-mint-bg-2.png 547w" alt="" className="discord-hero-bg-e-2 discord-hero-bg-e-2-mint-1"/></div>
                        <div className="discord-hero-bg-b-3 discord-hero-bg-b-3-mint-1"><img src="images/raffle-hero-bg-3.png" loading="lazy" sizes="(max-width: 1439px) 100vw, 1440.0001220703125px" srcset="images/raffle-hero-bg-3-p-500.png 500w, images/raffle-hero-bg-3-p-800.png 800w, images/raffle-hero-bg-3-p-1080.png 1080w, images/raffle-hero-bg-3.png 1440w" alt="" className="discord-hero-bg-e-3 discord-hero-bg-e-3-mint-1"/></div>
                        <div className="raffle-hero-bg-grey"></div>
                    </div>
                    </div>
                    <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
                    <div data-w-id="72d41d14-5454-c9cc-84f7-145c017696fc" className="navbar-bg-w"></div>
                    <div className="page-padd">
                        <div className="page-container-wide">
                        <div className="padd-vert-navbar">
                            <div className="navbar-cont-w">
                            <a href="https://www.boreddogeclub.io/" className="brand-img-c w-nav-brand">
                                <div className="brand-img-b"><img src="images/bdc-logo-2.svg" loading="lazy" alt="" className="brand-img-e"/></div>
                            </a>
                            <div className="navbar-cont-c-r">
                                <div className="navbar-button-w">
                                <div className="navbar-button-b">
                                    <a href="https://discord.gg/boreddogeclub" target="_blank" className="button w-inline-block">
                                    <div className="button-hover-b"></div>
                                    <div className="button-img-b"><img src="images/Discord-Logo-White_1Discord-Logo-White.png" loading="lazy" alt="" className="button-img-e"/><img src="images/logo-discord-black_1logo-discord-black.png" loading="lazy" alt="" className="button-img-e-black"/></div>
                                    <div className="button-text-b">
                                        <div className="button-text-e">Join the Discord</div>
                                    </div>
                                    </a>
                                </div>
                                </div>
                                <div className="menu-button w-nav-button">
                                <div className="w-icon-nav-menu"></div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="page-padd">
                    <div className="page-container">
                        <div className="padd-vert-discord-hero padd-vert-discord-hero-raffle-t">
                        <div className="discord-hero-cont-w discord-hero-cont-w-raffle-t">
                            <div className="discord-hero-cont-c discord-hero-cont-c-raffle-t">
                                <NotStarted />
                                <UserClaim />
                            <div className="timer-w">
                                <div className="timer-c">
                                <div className="timer-block-c">
                                    <div className="timer-number-b-3">
                                    <div className="timer-number-b-1">
                                        <div className="timer-number-num-w">
                                        <div className="num-c-hour-100">
                                            <div id="timer-num-c-hour-10" className="timer-num-c-hour-100">
                                            <div className="timer-number-num-b-1">
                                                <div className="timer-number-e">0</div>
                                                <div className="timer-number-e">1</div>
                                                <div className="timer-number-e">2</div>
                                                <div className="timer-number-e">3</div>
                                                <div className="timer-number-e">4</div>
                                                <div className="timer-number-e">5</div>
                                                <div className="timer-number-e">6</div>
                                                <div className="timer-number-e">7</div>
                                                <div className="timer-number-e">8</div>
                                                <div className="timer-number-e">9</div>
                                            </div>
                                            <div className="timer-number-num-b-1">
                                                <div className="timer-number-e">0</div>
                                                <div className="timer-number-e">1</div>
                                                <div className="timer-number-e">2</div>
                                                <div className="timer-number-e">3</div>
                                                <div className="timer-number-e">4</div>
                                                <div className="timer-number-e">5</div>
                                                <div className="timer-number-e">6</div>
                                                <div className="timer-number-e">7</div>
                                                <div className="timer-number-e">8</div>
                                                <div className="timer-number-e">9</div>
                                            </div>
                                            <div className="timer-number-num-b-1">
                                                <div className="timer-number-e">0</div>
                                                <div className="timer-number-e">1</div>
                                                <div className="timer-number-e">2</div>
                                                <div className="timer-number-e">3</div>
                                                <div className="timer-number-e">4</div>
                                                <div className="timer-number-e">5</div>
                                                <div className="timer-number-e">6</div>
                                                <div className="timer-number-e">7</div>
                                                <div className="timer-number-e">8</div>
                                                <div className="timer-number-e">9</div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="timer-number-grey-bg-10"></div>
                                    </div>
                                    <div className="timer-number-b-1">
                                        <div className="timer-number-num-w">
                                        <div className="num-c-hour-10">
                                            <div id="timer-num-c-hour-1" className="timer-num-c-hour-10">
                                            <div className="timer-number-num-b-1">
                                                <div className="timer-number-e">0</div>
                                                <div className="timer-number-e">1</div>
                                                <div className="timer-number-e">2</div>
                                                <div className="timer-number-e">3</div>
                                                <div className="timer-number-e">4</div>
                                                <div className="timer-number-e">5</div>
                                                <div className="timer-number-e">6</div>
                                                <div className="timer-number-e">7</div>
                                                <div className="timer-number-e">8</div>
                                                <div className="timer-number-e">9</div>
                                            </div>
                                            <div className="timer-number-num-b-1">
                                                <div className="timer-number-e">0</div>
                                                <div className="timer-number-e">1</div>
                                                <div className="timer-number-e">2</div>
                                                <div className="timer-number-e">3</div>
                                                <div className="timer-number-e">4</div>
                                                <div className="timer-number-e">5</div>
                                                <div className="timer-number-e">6</div>
                                                <div className="timer-number-e">7</div>
                                                <div className="timer-number-e">8</div>
                                                <div className="timer-number-e">9</div>
                                            </div>
                                            <div className="timer-number-num-b-1">
                                                <div className="timer-number-e">0</div>
                                                <div className="timer-number-e">1</div>
                                                <div className="timer-number-e">2</div>
                                                <div className="timer-number-e">3</div>
                                                <div className="timer-number-e">4</div>
                                                <div className="timer-number-e">5</div>
                                                <div className="timer-number-e">6</div>
                                                <div className="timer-number-e">7</div>
                                                <div className="timer-number-e">8</div>
                                                <div className="timer-number-e">9</div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="timer-number-grey-bg-10"></div>
                                    </div>
                                    <div className="timer-number-b-1">
                                        <div className="timer-number-num-w">
                                        <div className="num-c-hour-1">
                                            <div id="timer-num-c-hour-1" className="timer-num-c-hour-1">
                                            <div className="timer-number-num-b-1">
                                                <div className="timer-number-e">0</div>
                                                <div className="timer-number-e">1</div>
                                                <div className="timer-number-e">2</div>
                                                <div className="timer-number-e">3</div>
                                                <div className="timer-number-e">4</div>
                                                <div className="timer-number-e">5</div>
                                                <div className="timer-number-e">6</div>
                                                <div className="timer-number-e">7</div>
                                                <div className="timer-number-e">8</div>
                                                <div className="timer-number-e">9</div>
                                            </div>
                                            <div className="timer-number-num-b-1">
                                                <div className="timer-number-e">0</div>
                                                <div className="timer-number-e">1</div>
                                                <div className="timer-number-e">2</div>
                                                <div className="timer-number-e">3</div>
                                                <div className="timer-number-e">4</div>
                                                <div className="timer-number-e">5</div>
                                                <div className="timer-number-e">6</div>
                                                <div className="timer-number-e">7</div>
                                                <div className="timer-number-e">8</div>
                                                <div className="timer-number-e">9</div>
                                            </div>
                                            <div className="timer-number-num-b-1">
                                                <div className="timer-number-e">0</div>
                                                <div className="timer-number-e">1</div>
                                                <div className="timer-number-e">2</div>
                                                <div className="timer-number-e">3</div>
                                                <div className="timer-number-e">4</div>
                                                <div className="timer-number-e">5</div>
                                                <div className="timer-number-e">6</div>
                                                <div className="timer-number-e">7</div>
                                                <div className="timer-number-e">8</div>
                                                <div className="timer-number-e">9</div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="timer-number-grey-bg-10"></div>
                                    </div>
                                    </div>
                                    <div className="timer-text-b">
                                    <div className="timer-text-e">Hours</div>
                                    </div>
                                </div>
                                <div className="timer-block-c">
                                    <div className="timer-number-b">
                                    <div className="timer-number-b-1">
                                        <div className="timer-number-num-w">
                                        <div className="num-c-min-10">
                                            <div className="timer-num-c-min-10">
                                            <div className="timer-number-num-b-1">
                                                <div className="timer-number-e">0</div>
                                                <div className="timer-number-e">1</div>
                                                <div className="timer-number-e">2</div>
                                                <div className="timer-number-e">3</div>
                                                <div className="timer-number-e">4</div>
                                                <div className="timer-number-e">5</div>
                                            </div>
                                            <div className="timer-number-num-b-1">
                                                <div className="timer-number-e">0</div>
                                                <div className="timer-number-e">1</div>
                                                <div className="timer-number-e">2</div>
                                                <div className="timer-number-e">3</div>
                                                <div className="timer-number-e">4</div>
                                                <div className="timer-number-e">5</div>
                                            </div>
                                            <div className="timer-number-num-b-1">
                                                <div className="timer-number-e">0</div>
                                                <div className="timer-number-e">1</div>
                                                <div className="timer-number-e">2</div>
                                                <div className="timer-number-e">3</div>
                                                <div className="timer-number-e">4</div>
                                                <div className="timer-number-e">5</div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="timer-number-grey-bg-10"></div>
                                    </div>
                                    <div className="timer-number-b-1">
                                        <div className="timer-number-num-w">
                                        <div className="num-c-min-1">
                                            <div className="timer-num-c-min-1">
                                            <div className="timer-number-num-b-1">
                                                <div className="timer-number-e">0</div>
                                                <div className="timer-number-e">1</div>
                                                <div className="timer-number-e">2</div>
                                                <div className="timer-number-e">3</div>
                                                <div className="timer-number-e">4</div>
                                                <div className="timer-number-e">5</div>
                                                <div className="timer-number-e">6</div>
                                                <div className="timer-number-e">7</div>
                                                <div className="timer-number-e">8</div>
                                                <div className="timer-number-e">9</div>
                                            </div>
                                            <div className="timer-number-num-b-1">
                                                <div className="timer-number-e">0</div>
                                                <div className="timer-number-e">1</div>
                                                <div className="timer-number-e">2</div>
                                                <div className="timer-number-e">3</div>
                                                <div className="timer-number-e">4</div>
                                                <div className="timer-number-e">5</div>
                                                <div className="timer-number-e">6</div>
                                                <div className="timer-number-e">7</div>
                                                <div className="timer-number-e">8</div>
                                                <div className="timer-number-e">9</div>
                                            </div>
                                            <div className="timer-number-num-b-1">
                                                <div className="timer-number-e">0</div>
                                                <div className="timer-number-e">1</div>
                                                <div className="timer-number-e">2</div>
                                                <div className="timer-number-e">3</div>
                                                <div className="timer-number-e">4</div>
                                                <div className="timer-number-e">5</div>
                                                <div className="timer-number-e">6</div>
                                                <div className="timer-number-e">7</div>
                                                <div className="timer-number-e">8</div>
                                                <div className="timer-number-e">9</div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="timer-number-grey-bg-10"></div>
                                    </div>
                                    </div>
                                    <div className="timer-text-b">
                                    <div className="timer-text-e">Minutes</div>
                                    </div>
                                </div>
                                <div className="timer-block-c">
                                    <div className="timer-number-b">
                                    <div className="timer-number-b-1">
                                        <div className="timer-number-num-w">
                                        <div className="num-c-sec-10">
                                            <div className="timer-num-c-sec-10">
                                            <div className="timer-number-num-b-1">
                                                <div className="timer-number-e">0</div>
                                                <div className="timer-number-e">1</div>
                                                <div className="timer-number-e">2</div>
                                                <div className="timer-number-e">3</div>
                                                <div className="timer-number-e">4</div>
                                                <div className="timer-number-e">5</div>
                                            </div>
                                            <div className="timer-number-num-b-1">
                                                <div className="timer-number-e">0</div>
                                                <div className="timer-number-e">1</div>
                                                <div className="timer-number-e">2</div>
                                                <div className="timer-number-e">3</div>
                                                <div className="timer-number-e">4</div>
                                                <div className="timer-number-e">5</div>
                                            </div>
                                            <div className="timer-number-num-b-1">
                                                <div className="timer-number-e">0</div>
                                                <div className="timer-number-e">1</div>
                                                <div className="timer-number-e">2</div>
                                                <div className="timer-number-e">3</div>
                                                <div className="timer-number-e">4</div>
                                                <div className="timer-number-e">5</div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="timer-number-grey-bg-10"></div>
                                    </div>
                                    <div className="timer-number-b-1">
                                        <div className="timer-number-num-w">
                                        <div className="num-c-sec-1">
                                            <div className="timer-num-c-sec-1">
                                            <div className="timer-number-num-b-1">
                                                <div className="timer-number-e">0</div>
                                                <div className="timer-number-e">1</div>
                                                <div className="timer-number-e">2</div>
                                                <div className="timer-number-e">3</div>
                                                <div className="timer-number-e">4</div>
                                                <div className="timer-number-e">5</div>
                                                <div className="timer-number-e">6</div>
                                                <div className="timer-number-e">7</div>
                                                <div className="timer-number-e">8</div>
                                                <div className="timer-number-e">9</div>
                                            </div>
                                            <div className="timer-number-num-b-1">
                                                <div className="timer-number-e">0</div>
                                                <div className="timer-number-e">1</div>
                                                <div className="timer-number-e">2</div>
                                                <div className="timer-number-e">3</div>
                                                <div className="timer-number-e">4</div>
                                                <div className="timer-number-e">5</div>
                                                <div className="timer-number-e">6</div>
                                                <div className="timer-number-e">7</div>
                                                <div className="timer-number-e">8</div>
                                                <div className="timer-number-e">9</div>
                                            </div>
                                            <div className="timer-number-num-b-1">
                                                <div className="timer-number-e">0</div>
                                                <div className="timer-number-e">1</div>
                                                <div className="timer-number-e">2</div>
                                                <div className="timer-number-e">3</div>
                                                <div className="timer-number-e">4</div>
                                                <div className="timer-number-e">5</div>
                                                <div className="timer-number-e">6</div>
                                                <div className="timer-number-e">7</div>
                                                <div className="timer-number-e">8</div>
                                                <div className="timer-number-e">9</div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="timer-number-grey-bg-10"></div>
                                    </div>
                                    </div>
                                    <div className="timer-text-b">
                                    <div className="timer-text-e">Seconds</div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="discord-section-footer wf-section">
                    <div className="page-padd">
                    <div className="page-container">
                        <div className="padd-vert-footer-main padd-vert-footer-main-disc">
                        <div className="footer-cont-w">
                            <div className="footer-cont-c">
                            <div className="footer-head-b">
                                <a href="https://www.boreddogeclub.io/" className="brand-img-c w-nav-brand">
                                <div className="brand-img-b-footer"><img src="images/bdc-logo-2.svg" loading="lazy" alt="" className="brand-img-e"/></div>
                                </a>
                            </div>
                            <div className="footer-social-w">
                                <div className="footer-soc-c">
                                <div className="home-hero-soc-b">
                                    <a href="https://twitter.com/boredogeclub" target="_blank" className="soc-c w-inline-block">
                                    <div className="soc-b"><img src="images/logo-twitter-white.svg" loading="lazy" alt="" className="soc-img-e"/><img src="images/logo-twitter-blue.svg" loading="lazy" alt="" className="soc-img-e-an"/></div>
                                    </a>
                                </div>
                                <div className="home-hero-soc-b">
                                    <div className="button-discord-w">
                                    <div className="discord-letters-w rotate-e"></div>
                                    <a href="https://discord.gg/boreddogeclub" target="_blank" className="button-discord w-inline-block">
                                        <div className="button-hover-b button-hover-b-disc"></div>
                                        <div className="button-discord-circle">
                                        <div className="button-discord-c">
                                            <div className="button-discord-b"><img src="images/Discord-logo-white-33_1Discord-logo-white-33.png" loading="lazy" alt="" className="button-discord-e"/></div>
                                        </div>
                                        </div>
                                    </a>
                                    </div>
                                </div>
                                <div className="home-hero-soc-b">
                                    <a href="https://www.instagram.com/boreddogeclub/" target="_blank" className="soc-c w-inline-block">
                                    <div className="soc-b"><img src="images/logo-insta-white.svg" loading="lazy" alt="" className="soc-img-e"/><img src="images/logo-insta-blue.svg" loading="lazy" alt="" className="soc-img-e-an"/></div>
                                    </a>
                                </div>
                                </div>
                            </div>
                            <div className="footer-copyright-b">
                                <div className="footer-copyright-e">© 2022 Bored Doge Club NFT. All rights reserved</div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="mint-1-timer-w"></div>
            </div>
        </>
    )
}