import { useState, useEffect } from 'react';
import Config from "../config/index.js"
import {ethers} from 'ethers'

export default function useMint(contractSales, contractNFT, signer, provider, address) {
    const [dogesIds, setDogesIds] = useState();
    const [dogeClaimable, setDogeClaimable] = useState();
    
    const [error, setError] = useState();
    const [isMinting, setIsMinting] = useState();
    const [txHash, setTxHash] = useState();

    async function queryContracts() {
        if (contractSales && address) {
            const dogeBalance = await contractNFT.balanceOf(address);
            let ids = []
            for (let i = 0; i < dogeBalance.toNumber(); i++) {
                ids.push((await contractNFT.tokenOfOwnerByIndex(address, i)).toNumber())
            }
            console.log(ids)
            let claimable = [];
            if (ids.length > 0)
                claimable = (await contractSales.getStillClaimableDogesFromIds(ids)).map(c => c.toNumber()).filter(c => c!=0);
  
            setDogesIds(ids);
            setDogeClaimable(claimable);
        }
    }

    function setCorrectErrorMessage(e) {
        setError("An unexpected error occured and your transaction did not go through. Please check your wallet or etherscan to see the reason of the failure.");
        for (let error of Config.ERRORS.MESSAGES) {
            if (e.message.includes(error.error)) {
                let _msg = error.message;
                //if (error.insert)
                   // _msg = _msg.replace("[amount]", ethers.utils.formatEther(price.toString()))
                    setError(_msg)
                return;
            }
        }
    }

    async function claim() {
        if (contractSales && signer) {
            setError(undefined);
            if (!canUserClaim()) {
                setError("You don't have any Doge's that can claim.")
                return;
            }
            
            try {
                setIsMinting(true)
                
                const tx = await contractSales.connect(signer).claimDoges(dogeClaimable);
                setTxHash(tx.hash);
                await tx.wait();
                await queryContracts();
                window.open("/thank-you", "_self");
            } catch (e) {
                setCorrectErrorMessage(e);
            }

            setIsMinting(false);
        } else {
            alert("wallet not loaded");
        }
    }

    function isDataLoaded() {
        return dogesIds !== undefined &&
               dogeClaimable!== undefined 
    }

    function canUserClaim() {
        return isDataLoaded() && dogeClaimable.length > 0
    }

    useEffect(() => {
        if (provider && contractSales && address) {
            queryContracts();
            provider.on("block", _ => {
                console.log("block update")
                queryContracts();
            })
            return () => provider.off("block");
        }
    }, [provider, contractSales, address])

    return [
        isDataLoaded,
        dogesIds,
        dogeClaimable,
        canUserClaim,
        claim,
        isMinting,
        error,
        txHash
    ]
}