import { useState } from "react";
import Web3Modal from "web3modal";
// @ts-ignore
import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink"
import { ethers } from "ethers";
import Contracts from "../contracts/hardhat_contracts.json";

import Config from "../config";

const walletLink = new WalletLink({
    appName: "coinbase",
});

const walletLinkProvider = walletLink.makeWeb3Provider(`https://mainnet.infura.io/v3/4ef8c08bf64447b1951e191336800795`, 1);

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: "4ef8c08bf64447b1951e191336800795",
    },
  },
  "custom-walletlink": {
    display: {
      logo: "https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0",
      name: "Coinbase",
      description: "Connect to Coinbase Wallet (not Coinbase App)",
    },
    package: walletLinkProvider,
    connector: async (provider, _options) => {
      await provider.enable();
      return provider;
    },
  },
};

const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions,
  disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
});

export default function useWeb3() {
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [address, setAddress] = useState(null);
  const [contractNFT, setContractNFT] = useState(null);
  const [contractSales, setContractSales] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isCorrectChain, setIsCorrectChain] = useState(undefined);
  const [triedAutoConnecting, setTriedAutoConnecting] = useState(null);

  async function initiateWeb3(proxy) {
    const _provider = new ethers.providers.Web3Provider(proxy);

    _provider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      setIsConnected(false);
    });

    const _signer = await _provider.getSigner();
    const _address = await _signer.getAddress();
    let { chainId } = await _provider.getNetwork();
    let _isCorrectChainId = chainId === Config.NETWORK.TARGET_CHAIN_ID;
    chainId = chainId.toString();

    let _contractNFT;
    let _contractSales;
    if (_isCorrectChainId) {
      let Bears = Contracts[chainId][Config.NETWORK.CHAIN_ID_TO_NAME[chainId]]["contracts"][Config.CONTRACTS.NFT];
      _contractNFT = new ethers.Contract(Bears.address, Bears.abi, _provider);

      let Sales = Contracts[chainId][Config.NETWORK.CHAIN_ID_TO_NAME[chainId]]["contracts"][Config.CONTRACTS.SALES];
      _contractSales = new ethers.Contract(Sales.address, Sales.abi, _provider);
    } else {
        alert(`Please connect to the ${Config.NETWORK.CHAIN_ID_TO_NAME[Config.NETWORK.TARGET_CHAIN_ID]}`)
    }

    setProvider(_provider);
    setSigner(_signer);
    setAddress(_address);
    setContractNFT(_contractNFT);
    setContractSales(_contractSales);
    setIsConnected(true);
    setIsCorrectChain(_isCorrectChainId);
  }

  async function tryAutoConnect() {
    try {
      let { cachedProvider } = web3Modal;
      let cachedFromStorage = JSON.parse(localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER"));

      if (cachedProvider && cachedProvider !== "") {
        await initiateWeb3(await web3Modal.connectTo(cachedProvider));
        return true;
      } else if (cachedFromStorage && cachedFromStorage != "") {
        await initiateWeb3(await web3Modal.connectTo(cachedFromStorage));
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  async function connect() {
    setIsConnecting(true);
    try {
      // await web3Modal.clearCachedProvider();
      let _proxy = await web3Modal.connect();
      await initiateWeb3(_proxy);
    } catch (e) {
      if (e && e.message) alert(e.message);
    }
    setIsConnecting(false);
  }

  return [
    provider,
    signer,
    address,
    contractNFT,
    contractSales,
    isConnected,
    isCorrectChain,
    tryAutoConnect,
    triedAutoConnecting,
    setTriedAutoConnecting,
    connect,
    isConnecting,
  ];
}
